import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '@apiax/web-commons';

import { Feedback } from 'app/chatbot/feedback/feedback';
import { getConfig } from 'shared/app';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(
    private httpClient: HttpClient,
    private notificationsService: NotificationService
  ) {}

  sendFeedback(chatId: string, messageId: string, feedback: Feedback): Observable<Feedback> {
    return this.httpClient.put<Feedback>(`${this.apiUrl}${chatId}/${messageId}`, feedback).pipe(
      catchError((error: HttpErrorResponse) => {
        this.notificationsService.showSimpleAlert(error.message, 'error');
        return of(feedback);
      })
    );
  }

  sendFeedbackReference(chatId: string, messageId: string, documentid: string, feedback: Feedback) {
    return this.httpClient.put<Feedback>(`${this.apiUrl}${chatId}/${messageId}/${documentid}`, feedback).pipe(
      catchError((error: HttpErrorResponse) => {
        this.notificationsService.showSimpleAlert(error.message, 'error');
        return of(feedback);
      })
    );
  }

  private get apiUrl(): string {
    return getConfig('apiUrl') + '/feedback/';
  }
}
