import { Injectable } from '@angular/core';
import { NotificationService } from '@apiax/web-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { ChatService } from 'shared/app/services/chat.service';
import { FeedbackService } from '../../../chatbot/feedback/feedback.service';
import { ChatbotMessage } from '../../../models/chatbot-message';
import { MessageReference, MessageReferencedSpans } from '../../../models/chatbot-response';
import { FetchChatPreviews, UpdateChatPreviewTitle } from '../chat-preview/chat-preview.action';
import { SetShowJumpingDots, SetSwitchingChatAvailable, SetUserInputDisabled } from '../settings/settings.action';
import { SettingsState } from '../settings/settings.state';

import {
  AddEditMessageToChatMessage,
  CancelChatRequests,
  ClearChatBotMessages,
  SendUserMessage,
  SetChatId,
  StoreChatBotMessage,
  StoreChatBotReferences,
  StoreFeedbackItem,
  StoreFeedbackReference,
  StoreUserMessage,
  UpdateChatBotMessage,
  UpdateReferencesContext
} from './chat.action';
import { TranslateService } from '@ngx-translate/core';

export interface ChatStateModel {
  chatId: string;
  chatBotMessages: ChatbotMessage[];
  messageReferences: MessageReference[];
}

export const DEFAULT_STATE: ChatStateModel = {
  chatId: null,
  chatBotMessages: [],
  messageReferences: []
};

@UntilDestroy()
@State<ChatStateModel>({
  name: 'chat',
  defaults: DEFAULT_STATE
})
@Injectable()
export class ChatState {
  httpRequest: Subscription[] = [];

  constructor(
    private store: Store,
    private chatService: ChatService,
    private feedbackService: FeedbackService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  @Selector()
  static chatId(state: ChatStateModel): string {
    return state.chatId;
  }

  @Selector()
  public static getChatBotMessages(state: ChatStateModel): ChatbotMessage[] {
    return state.chatBotMessages;
  }

  @Selector()
  public static getMessageReferences(state: ChatStateModel): MessageReference[] {
    return state.messageReferences;
  }

  @Action(SetChatId)
  public setChatId(context: StateContext<ChatStateModel>, action: SetChatId): void {
    context.patchState({
      chatId: action.payload
    });
  }

  @Action(CancelChatRequests)
  public cancelChatRequests(context: StateContext<ChatStateModel>) {
    this.httpRequest.forEach(subscription => subscription.unsubscribe());
    this.httpRequest = [];
    context.dispatch(new SetShowJumpingDots(false));
  }

  @Action(SendUserMessage)
  public sendUserMessage(context: StateContext<ChatStateModel>, action: SendUserMessage): void {
    const { messageWrapper, invokedBySendMessage } = action.payload;
    const state = context.getState();
    const { chatBotMessages, chatId } = state;
    const chatLanguage = this.store.selectSnapshot(SettingsState.getChatLanguage);
    const selectedContext = this.store.selectSnapshot(SettingsState.getSelectedContext);
    const selectedModel = this.store.selectSnapshot(SettingsState.getSelectedModel);
    const chatbotConfigurationId = this.store.selectSnapshot(SettingsState.getChatbotConfigurationId);

    context.dispatch(new SetUserInputDisabled(true));
    context.dispatch(new SetShowJumpingDots(true));
    context.dispatch(new SetSwitchingChatAvailable(false));
    if (chatId === null) {
      let starterMessageText = this.translateService.instant('STARTER_MESSAGE');

      const starterMessage: ChatbotMessage = {
        message: starterMessageText,
        type: 'text',
        source: 'bot'
      };

      context.patchState({
        chatBotMessages: [starterMessage, messageWrapper]
      });

      const collectionId = this.store.selectSnapshot(SettingsState.getCollectionId);

      const request = this.chatService
        .startConversation(
          chatLanguage,
          selectedContext,
          selectedModel,
          chatbotConfigurationId,
          starterMessageText,
          collectionId
        )
        .pipe(untilDestroyed(this))
        .subscribe(chatbotResponse => {
          if (chatbotResponse.chatId != null) {
            context.dispatch(new SetChatId(chatbotResponse.chatId));
            context.dispatch(
              new SendUserMessage({
                messageWrapper: messageWrapper,
                invokedBySendMessage: true
              })
            );
            this.chatService
              .updateSessionTitle(chatbotResponse.chatId, messageWrapper.message, chatLanguage)
              .subscribe((updatedTitle: string) => {
                context.dispatch(
                  new UpdateChatPreviewTitle({
                    chatId: chatbotResponse.chatId,
                    title: updatedTitle
                  })
                );
              });
          } else {
            context.dispatch(new SetShowJumpingDots(false));
            this.notificationService.showSimpleAlert(chatbotResponse.message, 'error');
          }
        });
      this.httpRequest.push(request);
    } else {
      if (!invokedBySendMessage) {
        context.patchState({
          chatBotMessages: [...chatBotMessages, messageWrapper]
        });
      }
      const mode = selectedContext;
      if (mode === 'Cross-Border Rules Assistant') {
        const request = this.chatService
          .sendRulesMessage(messageWrapper, chatId)
          .pipe(untilDestroyed(this))
          .subscribe(chatbotResponse => {
            context.dispatch(new StoreChatBotMessage(chatbotResponse));
            context.dispatch(new SetUserInputDisabled(false));
            this.store.dispatch(new FetchChatPreviews());
            context.dispatch(new SetSwitchingChatAvailable(true));
          });
        this.httpRequest.push(request);
      } else if (mode === 'Taxonomy Assistant') {
        const request = this.chatService
          .sendTaxonomyMessage(messageWrapper.message, chatId)
          .pipe(untilDestroyed(this))
          .subscribe(chatbotResponse => {
            context.dispatch(new StoreChatBotMessage(chatbotResponse));
            context.dispatch(new SetUserInputDisabled(false));
            this.store.dispatch(new FetchChatPreviews());
            context.dispatch(new SetSwitchingChatAvailable(true));
          });
        this.httpRequest.push(request);
      } else {
        const collectionId = this.store.selectSnapshot(SettingsState.getCollectionId);
        if (collectionId === '66ced788b92f010a52d8ff29') {
          const request = this.chatService
            .sendTaxonomyMessage(messageWrapper.message, chatId)
            .pipe(untilDestroyed(this))
            .subscribe(chatbotResponse => {
              context.dispatch(new StoreChatBotMessage(chatbotResponse));
              context.dispatch(new SetUserInputDisabled(false));
              this.store.dispatch(new FetchChatPreviews());
              context.dispatch(new SetSwitchingChatAvailable(true));
            });
          this.httpRequest.push(request);
        } else {
          const request = this.chatService
            .sendMessage(messageWrapper.message, chatId)
            .pipe(untilDestroyed(this))
            .subscribe(chatbotResponse => {
              context.dispatch(new StoreChatBotMessage(chatbotResponse));
              context.dispatch(new SetUserInputDisabled(false));
              this.store.dispatch(new FetchChatPreviews());
              context.dispatch(new SetSwitchingChatAvailable(true));
            });
          this.httpRequest.push(request);
        }
      }
    }
  }

  @Action(StoreChatBotMessage)
  public storeChatBotMessages(context: StateContext<ChatStateModel>, action: StoreChatBotMessage): void {
    const state = context.getState();
    context.patchState({
      chatBotMessages: [
        ...state.chatBotMessages,
        {
          messageId: action.payload.messageId,
          message: action.payload.message,
          type: action.payload.type,
          source: 'bot',
          evaluatedRulesets: action.payload.evaluatedRulesets,
          evaluationInformation: action.payload.evaluationInformation,
          referencedSpans: action.payload.referencedSpans,
          availableOptions: action.payload.availableOptions,
          result: action.payload.result,
          terms: action.payload.terms,
          feedback: action.payload.feedback,
          verifiable: action.payload.verifiable,
          editMessage: action.payload.editMessage,
          regprop: action.payload.regprop
        }
      ]
    });
    context.dispatch(new SetShowJumpingDots(false));
  }

  @Action(StoreUserMessage)
  public storeUserMessages(context: StateContext<ChatStateModel>, action: StoreUserMessage): void {
    const state = context.getState();
    context.patchState({
      chatBotMessages: [
        ...state.chatBotMessages,
        {
          messageId: action.payload.messageId,
          message: action.payload.message,
          type: action.payload.type,
          source: 'user'
        }
      ]
    });
  }

  @Action(UpdateChatBotMessage)
  public updateChatBotMessages(context: StateContext<ChatStateModel>, action: UpdateChatBotMessage): void {
    const state = context.getState();
    const chatBotMessages = state.chatBotMessages;
    const index = chatBotMessages.findIndex(message => message.messageId === action.payload.messageId);
    if (index !== -1) {
      chatBotMessages[index] = {
        messageId: action.payload.messageId,
        message: action.payload.message,
        type: action.payload.type,
        source: 'bot',
        referencedSpans: action.payload.referencedSpans,
        availableOptions: action.payload.availableOptions,
        result: action.payload.result,
        terms: action.payload.terms,
        feedback: action.payload.feedback
      };
    } else {
      chatBotMessages.push({
        messageId: action.payload.messageId,
        message: action.payload.message,
        type: action.payload.type,
        source: 'bot',
        referencedSpans: action.payload.referencedSpans,
        availableOptions: action.payload.availableOptions,
        result: action.payload.result,
        terms: action.payload.terms
      });
    }
    context.patchState({
      chatBotMessages: chatBotMessages
    });
  }

  @Action(ClearChatBotMessages)
  public clearChatBotMessages(context: StateContext<ChatStateModel>): void {
    context.patchState({
      chatBotMessages: [],
      messageReferences: []
    });
  }

  @Action(StoreChatBotReferences)
  public storeChatBotReferences(context: StateContext<ChatStateModel>, action: StoreChatBotReferences): void {
    const state = context.getState();
    context.patchState({
      messageReferences: [
        ...state.messageReferences,
        {
          referenceId: action.payload.referenceId,
          referencedSpans: action.payload.referencedSpans,
          lastSentence: action.payload.lastSentence
        }
      ]
    });
  }

  @Action(UpdateReferencesContext)
  public updateReferencesContext(context: StateContext<ChatStateModel>, action: UpdateReferencesContext): void {
    const state = context.getState();
    const messageReferences = state.messageReferences;
    const index = messageReferences.findIndex(reference => reference.referenceId === action.payload.referenceId);
    if (index !== -1) {
      messageReferences[index].referencedSpans = action.payload.context;
    }
    context.patchState({
      messageReferences: messageReferences
    });
  }

  @Action(StoreFeedbackItem)
  public storeFeedbackItem(context: StateContext<ChatStateModel>, action: StoreFeedbackItem): void {
    const state = context.getState();
    const chatBotMessages = state.chatBotMessages;
    const index = chatBotMessages.findIndex(message => message.messageId === action.payload.messageId);
    if (index !== -1) {
      chatBotMessages[index].feedback = action.payload.feedback;
      this.feedbackService
        .sendFeedback(context.getState().chatId, action.payload.messageId, action.payload.feedback)
        .pipe(untilDestroyed(this))
        .subscribe();
    }
    context.patchState({
      chatBotMessages: chatBotMessages
    });
  }

  @Action(StoreFeedbackReference)
  public storeFeedbackReference(context: StateContext<ChatStateModel>, action: StoreFeedbackReference): void {
    const { chatId } = context.getState();
    // eslint-disable-next-line prefer-const
    let { messageId, documentid, feedback } = action.payload;
    messageId = action.payload.messageId.slice(0, -2);

    this.feedbackService
      .sendFeedbackReference(chatId, messageId, documentid, feedback)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const { messageReferences } = context.getState();
        const referenceID = messageReferences.findIndex(
          (reference: MessageReference) => reference.referenceId === action.payload.messageId
        );

        const referencedSpansIndex = messageReferences[referenceID].referencedSpans.findIndex(
          (referencedSpans: MessageReferencedSpans) => {
            return referencedSpans.sections.findIndex(section => section.documentId === documentid) !== -1;
          }
        );
        const sectionIndex = messageReferences[referenceID].referencedSpans[referencedSpansIndex].sections.findIndex(
          section => section.documentId === documentid
        );

        messageReferences[referenceID].referencedSpans[referencedSpansIndex].sections[sectionIndex].feedback = feedback;

        context.patchState({
          messageReferences: messageReferences
        });
      });
  }

  @Action(AddEditMessageToChatMessage)
  public addEditMessageToChatMessage(context: StateContext<ChatStateModel>, action: AddEditMessageToChatMessage): void {
    const { chatBotMessages } = context.getState();
    const index = chatBotMessages.findIndex(message => message.messageId === action.payload.messageId);
    if (index !== -1) {
      chatBotMessages[index].editMessage = action.payload.editMessage;
    }
    context.patchState({
      chatBotMessages: chatBotMessages
    });
  }
}
