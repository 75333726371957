import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  Application,
  HostComponent,
  HostService,
  InfoBarService,
  NavigationBarConfig,
  NavigationBarService,
  User as NavigationBarUser
} from '@apiax/web-commons';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNull } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { getConfig } from 'shared/app';

import { Store } from '@ngxs/store';
import { AuthorizationService } from 'shared/core';
import { PhotoDAO } from './domain/daos/photo.dao';
import { ErrorsState } from './domain/stores/errors/chat-errors.state';

@UntilDestroy()
@Component({
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('verificationsPanelHost')
  public verificationsPanelHost: HostComponent;

  @ViewChild('verificationsPanelView')
  verificationsPanelView;

  public currentUser: NavigationBarUser;
  public userPhotoUrl$ = new BehaviorSubject<any>(null);
  public navigationBarConfig: NavigationBarConfig;
  public collapsed: boolean;
  public errorMessage$: Observable<string>;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authorizationService: AuthorizationService,
    private navBarService: NavigationBarService,
    private hostService: HostService,
    public infoBarService: InfoBarService,
    private store: Store
  ) {
    this.getUser();

    this.navigationBarConfig = {
      appsUrl: getConfig('appsWebUrl'),
      rulesUrl: getConfig('rulesWebUrl'),
      taxonomyUrl: getConfig('taxonomyWebUrl'),
      reportingUrl: getConfig('reportingWebUrl'),
      companyManagerUrl: getConfig('companyManagerWebUrl'),
      monitoringUrl: getConfig('monitoringWebUrl'),
      clientProfileManagerUrl: getConfig('clientProfileManagerWebUrl'),
      instrumentManagerUrl: getConfig('instrumentManagerWebUrl'),
      instrumentsCheckUrl: getConfig('instrumentsCheckWebUrl'),
      documentsUrl: getConfig('documentsWebUrl'),
      chatbotUrl: '',
      requestsUrl: getConfig('requestsWebUrl'),
      knowledgeBaseUrl: getConfig('knowledgebaseWebUrl'),
      contentCheckUrl: getConfig('contentCheckWebUrl'),
      userPhotoUrl$: this.userPhotoUrl$,
      currentApplication: Application.Chatbot
    };

    this.hostService
      .getInitComponent()
      .pipe(untilDestroyed(this))
      .subscribe(event => this.infoBarCallback(event));
  }

  public onLogout() {
    this.authorizationService.logout();
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.cdr.markForCheck();
      });

    this.navBarService
      .getCollapsible()
      .pipe(untilDestroyed(this))
      .subscribe(collapsed => {
        this.collapsed = collapsed;
      });

    this.initErrorMessageListener();
  }

  ngAfterViewInit() {
    this.initVerificationsPanel();
  }

  private getUser() {
    this.authorizationService.currentUser.pipe(untilDestroyed(this)).subscribe(user => {
      if (user) {
        this.currentUser = {
          organizationName: user.organization?.name || '',
          firstName: user.firstName,
          lastName: user.lastName,
          organizationId: user.organizationId,
          photoId: user.photoId,
          permissions: user.activities
        };

        this.getUserPhotoUrl();
      }
    });
  }

  private getUserPhotoUrl() {
    if (this.currentUser.photoId) {
      this.userPhotoUrl$.next(PhotoDAO.getPhotoUrl(this.currentUser.photoId));
    } else {
      this.userPhotoUrl$.next(null);
    }
  }

  public infoBarCallback(event: string) {
    if (event === 'verifications') {
      this.hostService.setComponent('verifications', this.verificationsPanelView);
    }
  }

  private initVerificationsPanel() {
    this.hostService
      .getActiveHosts()
      .pipe(
        filter(result => !isNull(result)),
        untilDestroyed(this)
      )
      .subscribe(result => {
        const component = result?.get('verifications');

        if (component instanceof TemplateRef) {
          this.verificationsPanelHost.loadTemplate(component);
        } else {
          this.verificationsPanelHost.loadComponent(component);
        }
        this.cdr.markForCheck();
      });
  }

  private initErrorMessageListener() {
    this.errorMessage$ = this.store.select(ErrorsState.chatSessionError).pipe(untilDestroyed(this));
  }
}
