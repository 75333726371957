<apx-template-modal [data]="{ title: addCommentTitle }" [template]="modalTemplate"> </apx-template-modal>

<ng-template #modalTemplate>
  <mat-dialog-content>
    <ng-template [ngIf]="quickReplyEnabled">
      <div class="quick-reply">
        <span>{{ 'FEEDBACK.QUICK_FEEDBACK' | translate }}</span>
        <div class="options">
          <ng-template [ngForOf]="quickReplies" let-option ngFor>
            <span class="option" [ngClass]="{ clicked: quickReply === option }" (click)="quickReplyClicked(option)">
              {{ 'FEEDBACK.QUICK_FEEDBACK_OPTIONS.' + option | translate }}
            </span>
          </ng-template>
        </div>
      </div>
    </ng-template>
    <div class="comment">
      <span>{{ 'FEEDBACK.ADD_COMMENT' | translate }}</span>
      <textarea [formControl]="commentControl"></textarea>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="actions-wrapper">
    <button class="btn-action primary-action" (click)="onSave()">
      {{ 'FEEDBACK.SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
