import { StateContext, Store } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { of, Subscription, throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { ConversationLogService } from 'shared/app/services/conversation-log.service';
import { ChatPreviewApiService } from '../../../../../generated';
import { ChatSession } from '../../../../models/chatbot-response';
import { StoreValueStatus } from '../../../../models/store-value-status.model';
import { VerificationStatus } from '../../../../models/verification-status.model';
import { ChatPreviewMapper } from '../../../mappers/chat-preview-mapper';
import { ChatSessionVerificationsMapper } from '../../../mappers/chat-session-verifications-mapper';
import { ClearChatBotMessages, SetChatId, StoreChatBotMessage, StoreUserMessage } from '../../chat/chat.action';
import {
  SetChatLanguage,
  SetSwitchingChatAvailable,
  SetUserInputDisabled,
  StoreCollectionId
} from '../../settings/settings.action';
import { SettingsState } from '../../settings/settings.state';
import {
  AddNewVerificationRequestToChatPreview,
  ChangeConversation,
  DeleteConversation,
  EditConversation,
  ResetChatPreviewHasUpdate,
  UpdateChatPreviewActivity,
  UpdateChatPreviewTitle,
  UpdateVerificationRequestOnChatPreview
} from '../chat-preview.action';
import { ChatPreviewStateModel } from '../chat-preview.state';

export class ChatPreviewUseCases {
  constructor(
    private store: Store,
    private conversationLogService: ConversationLogService,
    private chatPreviewApiService: ChatPreviewApiService
  ) {}

  private httpRequest: Subscription[] = [];

  public fetchChatPreviews(context: StateContext<ChatPreviewStateModel>) {
    context.patchState({
      chatPreviewsFieldLoadStatus: StoreValueStatus.LOADING
    });
    const chatbotConfigurationId = this.store.selectSnapshot(SettingsState.getChatbotConfigurationId);
    if (chatbotConfigurationId != null) {
      const request = this.chatPreviewApiService
        .previewResponse({
          chatConfigurationId: chatbotConfigurationId
        })
        .pipe(first())
        .subscribe(response => {
          context.patchState({
            chatPreviews: ChatPreviewMapper.mapToChatPreviewModels(response.chatPreviews),
            chatPreviewsFieldLoadStatus: StoreValueStatus.LOADED
          });
        });

      this.httpRequest.push(request);
    }
  }

  public clearChatPreviews(context: StateContext<ChatPreviewStateModel>) {
    context.patchState({
      chatPreviews: [],
      chatPreviewsFieldLoadStatus: StoreValueStatus.NOT_LOADED
    });
    return of(true);
  }

  public deleteConversation(context: StateContext<ChatPreviewStateModel>, action: DeleteConversation) {
    const deletedChatId = action.payload.chatId;
    return this.conversationLogService.deleteChat(deletedChatId).pipe(
      first(),
      tap(response => {
        if (response.deleted === true) {
          context.dispatch(new ChangeConversation({ chatId: null }));
          context.patchState({
            ...context,
            chatPreviews: context.getState().chatPreviews.filter(chat => chat.id !== deletedChatId)
          });
        }
      })
    );
  }

  public editConversation(_context: StateContext<ChatPreviewStateModel>, action: EditConversation) {
    return this.conversationLogService.editChat(action.payload.chatId, action.payload.name).pipe(first());
  }

  public async changeConversation(context: StateContext<ChatPreviewStateModel>, action: ChangeConversation) {
    this.store.dispatch(new SetSwitchingChatAvailable(false));
    this.store.dispatch(new SetUserInputDisabled(true));
    this.httpRequest.forEach(request => request?.unsubscribe());
    this.httpRequest = [];
    if (action.payload == null || action.payload.chatId == null) {
      const localStorageLanguage = localStorage.getItem('language');
      const availableLanguages = this.store.selectSnapshot(SettingsState.getAvailableLanguages);
      let languageToSet = availableLanguages[0].language;
      if (localStorageLanguage != null) {
        const languageInStorage = availableLanguages.find(lang => lang.language === localStorageLanguage);
        if (languageInStorage != null) {
          languageToSet = languageInStorage.language;
        }
      }
      this.store.dispatch(new SetChatLanguage(languageToSet));
      this.store.dispatch(new ClearChatBotMessages());
      this.store.dispatch(new SetUserInputDisabled(false));
      this.store.dispatch(new SetSwitchingChatAvailable(true));
      this.store.dispatch(new StoreCollectionId(null));
    } else {
      await new Promise<void>((resolve, reject) => {
        this.conversationLogService
          .changeChat(action.payload.chatId)
          .pipe(first())
          .subscribe((chatSession: ChatSession) => {
            this.store.dispatch(new SetChatLanguage(chatSession.language));
            this.store.dispatch(new StoreCollectionId(chatSession.collectionId));
            //Create new Action Clear ChatbotMessages
            this.store.dispatch(new ClearChatBotMessages());
            this.addMessageToChatbotMessages(chatSession);
            this.store.dispatch(new SetUserInputDisabled(false));
            this.store.dispatch(new SetSwitchingChatAvailable(true));
            this.store.dispatch(
              new ResetChatPreviewHasUpdate({
                chatId: action.payload.chatId
              })
            );
            resolve();
          }, reject);
      });
    }
    context.dispatch(new SetChatId(action.payload.chatId));
  }

  private addMessageToChatbotMessages(chatSession: ChatSession) {
    //Remove first entry if topic set since topic is static
    chatSession.chatHistory.forEach(chatMessage => {
      if (chatMessage.role === 'assistant') {
        this.store.dispatch(
          new StoreChatBotMessage({
            message: chatMessage.content,
            type: chatMessage.type,
            messageId: chatMessage.messageId,
            chatId: chatSession.id,
            referencedSpans: chatMessage.referencedSpans,
            evaluatedRulesets: chatMessage.evaluatedRulesets,
            evaluationInformation: chatMessage.evaluationInformation,
            availableOptions: [],
            result: chatMessage.result,
            terms: chatMessage.terms,
            feedback: chatMessage.feedback,
            verifiable: chatMessage.verifiable,
            editMessage: ChatSessionVerificationsMapper.mapEditMessageToEditMessageModel(chatMessage.edit),
            regprop: chatMessage.regprop
          })
        );
      } else {
        this.store.dispatch(
          new StoreUserMessage({
            message: chatMessage.content,
            type: chatMessage.type,
            messageId: chatMessage.messageId
          })
        );
      }
    });
  }

  public addNewVerificationRequestToChatPreview(
    context: StateContext<ChatPreviewStateModel>,
    action: AddNewVerificationRequestToChatPreview
  ) {
    const chatPreviews = cloneDeep(context.getState().chatPreviews).map(c => {
      if (c.id === action.payload.chatId) {
        if (!c.verificationPreviews) {
          c.verificationPreviews = [];
        }
        if (!c.verificationPreviews.find(v => v.verificationId === action.payload.verificationId)) {
          c.verificationPreviews.push({
            chatId: c.id,
            status: VerificationStatus.REQUESTED,
            chatMessageId: action.payload.chatMessageId,
            verificationId: action.payload.verificationId
          });
        }
      }
      return c;
    });
    context.patchState({
      chatPreviews: chatPreviews
    });
  }

  public resetChatPreviewHasUpdate(context: StateContext<ChatPreviewStateModel>, action: ResetChatPreviewHasUpdate) {
    if (!action.payload.chatId) {
      return of(undefined);
    }
    return this.chatPreviewApiService
      .resetHasUpdate({
        chatId: action.payload.chatId
      })
      .pipe(
        tap(() => {
          const chatPreviews = cloneDeep(context.getState().chatPreviews).map(c => {
            if (c.id === action.payload.chatId) {
              c.hasUpdate = false;
            }
            return c;
          });
          context.patchState({
            chatPreviews: chatPreviews
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public updateVerificationRequestOnChatPreview(
    context: StateContext<ChatPreviewStateModel>,
    action: UpdateVerificationRequestOnChatPreview
  ) {
    const chatPreviews = cloneDeep(context.getState().chatPreviews);
    const preview = chatPreviews.find(c => c.id === action.payload.chatId);

    if (preview) {
      preview.hasUpdate = preview.hasUpdate || action.payload.status === VerificationStatus.COMPLETED;

      if (!preview.verificationPreviews) {
        preview.verificationPreviews = [];
      }

      const verification = preview.verificationPreviews.find(
        verificationPreview => verificationPreview.verificationId === action.payload.verificationId
      );
      if (verification) {
        verification.status = action.payload.status;
        if (action.payload.verification) {
          verification.updatedAt = action.payload.verification?.updatedAt;
          verification.assignee = action.payload.verification?.assignee;
        }
      } else {
        preview.verificationPreviews.push(ChatPreviewMapper.mapToVerificationPreview(action));
      }
    }

    context.patchState({
      chatPreviews: chatPreviews
    });
  }

  public updateChatPreviewActivity(context: StateContext<ChatPreviewStateModel>, action: UpdateChatPreviewActivity) {
    const chatPreviews = cloneDeep(context.getState().chatPreviews).map(c => {
      if (c.id === action.payload.chatId) {
        c.active = action.payload.active;
        c.hasUpdate = action.payload.hasUpdate;
      }
      return c;
    });

    context.patchState({
      chatPreviews: chatPreviews
    });
  }

  public updateChatPreviewTitle(context: StateContext<ChatPreviewStateModel>, action: UpdateChatPreviewTitle) {
    const chatPreviews = cloneDeep(context.getState().chatPreviews).map(c => {
      if (c.id === action.payload.chatId) {
        c.title = action.payload.title;
      }
      return c;
    });

    context.patchState({
      chatPreviews: chatPreviews
    });
  }
}
