// angular
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';

import { AppService, AuthorizationService, RouterExtensions, StorageManagerService } from './services';

export const CORE_PROVIDERS: any[] = [AppService, AuthorizationService, RouterExtensions, StorageManagerService];

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */
@NgModule({
  declarations: [],
  exports: [CommonModule, RouterModule],
  imports: [CommonModule, RouterModule, NgxsModule.forFeature([])],
  providers: [CORE_PROVIDERS, provideHttpClient(withInterceptorsFromDi())]
})
export class CoreModule {
  // configuredProviders: *required to configure WindowService and ConsoleService per platform
  public static forRoot(configuredProviders: any[]): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: configuredProviders
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule already loaded; Import in root module only.');
    }
  }
}
