<apx-template-modal [data]="{ title: referenceTitle }" [template]="modalTemplate"> </apx-template-modal>

<ng-template #modalTemplate>
  <mat-dialog-content class="referenceModalContent">
    <p class="lastSentence" [innerHTML]="data.referencedSpans[0].lastSentence | safeHtml"></p>
    <p class="modal-info">{{ 'DOCUMENTS_REFERENCES.INFO' | translate }}</p>

    <mat-accordion>
      <ng-template [ngForOf]="data.referencedSpans" let-referencedSpans let-referencedSpansIndex="index" ngFor>
        <mat-expansion-panel
          (closed)="referencedSpans.expanded = false"
          (opened)="referencedSpans.expanded = true"
          [expanded]="referencedSpans.expanded"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="context-filename">
                <mat-icon class="material-icons-outlined toggle">
                  {{ referencedSpans.expanded ? 'remove' : 'add' }}
                </mat-icon>
                {{ referencedSpans.filename }}
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template [ngIf]="referencedSpans.expanded">
            <div class="sections">
              <ng-template [ngForOf]="referencedSpans.sections" let-section let-sectionIndex="index" ngFor>
                <div class="section">
                  <div class="section-title">
                    <span>{{ section.sectionName }}</span>
                    <span class="icon-bar" *ngIf="data.showFeedback">
                      <mat-icon
                        class="material-icons-outlined"
                        [matTooltip]="'FEEDBACK.BOTMESSAGE' | translate"
                        [matTooltipPosition]="'above'"
                        [ngClass]="section.feedback?.comment ? 'active' : ''"
                        (click)="onOpenAddCommentModal(referencedSpansIndex, sectionIndex)"
                        >add_comment</mat-icon
                      >
                      <mat-icon
                        class="material-icons-outlined"
                        [ngClass]="section.feedback?.thumb === 'down' ? 'active' : ''"
                        (click)="onThumbDownClick(referencedSpansIndex, sectionIndex)"
                        >thumb_down</mat-icon
                      >
                      <mat-icon
                        class="material-icons-outlined"
                        [ngClass]="section.feedback?.thumb === 'up' ? 'active' : ''"
                        (click)="onThumbUpClick(referencedSpansIndex, sectionIndex)"
                        >thumb_up</mat-icon
                      >
                    </span>
                  </div>
                  <div class="section-content">
                    <span [innerHTML]="section.text"></span>
                  </div>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </ng-template>
    </mat-accordion>
  </mat-dialog-content>
</ng-template>
