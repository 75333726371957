import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ChatbotConfiguration } from '../../../models/chatbot-configuration.model';
import { StoreValueStatus } from '../../../models/store-value-status.model';
import { ChatbotConfigurationsService } from '../../services/chatbotconfigurations/chatbot-configurations.service';
import { LoadChatbotConfigurationPhoto, LoadChatbotConfigurations } from './chatbot-configurations.action';
import { ChatbotConfigurationsUseCases } from './usecases/chatbot-configurations-use-cases';

export interface ChatbotConfigurationsStateModel {
  chatbotConfigurations: ChatbotConfiguration[];
  chatbotConfigurationsStatus: StoreValueStatus;
  chatbotConfigurationsPhotosStatus: StoreValueStatus;
}

export const DEFAULT_STATE: ChatbotConfigurationsStateModel = {
  chatbotConfigurations: [],
  chatbotConfigurationsStatus: StoreValueStatus.NOT_LOADED,
  chatbotConfigurationsPhotosStatus: StoreValueStatus.NOT_LOADED
};

@State<ChatbotConfigurationsStateModel>({
  name: 'chatbotConfigurations',
  defaults: DEFAULT_STATE
})
@Injectable()
export class ChatbotConfigurationsState {
  private chatbotConfigurationsUseCases: ChatbotConfigurationsUseCases;

  constructor(chatbotConfigurationsService: ChatbotConfigurationsService) {
    this.chatbotConfigurationsUseCases = new ChatbotConfigurationsUseCases(chatbotConfigurationsService);
  }

  @Selector()
  public static getChatbotConfigurations(state: ChatbotConfigurationsStateModel): ChatbotConfiguration[] {
    return state.chatbotConfigurations;
  }

  @Selector()
  public static isChatbotConfigurationsLoading(state: ChatbotConfigurationsStateModel): boolean {
    return state.chatbotConfigurationsStatus === StoreValueStatus.LOADING;
  }

  @Selector()
  public static isChatbotConfigurationsLoaded(state: ChatbotConfigurationsStateModel): boolean {
    return state.chatbotConfigurationsStatus === StoreValueStatus.LOADED;
  }

  @Selector()
  public static isChatbotConfigurationsPhotosLoading(state: ChatbotConfigurationsStateModel): boolean {
    return state.chatbotConfigurationsPhotosStatus === StoreValueStatus.LOADING;
  }

  @Action(LoadChatbotConfigurations)
  public loadChatbotConfigurations(
    context: StateContext<ChatbotConfigurationsStateModel>,
    action: LoadChatbotConfigurations
  ) {
    return this.chatbotConfigurationsUseCases.loadChatbotConfigurations(context, action);
  }

  @Action(LoadChatbotConfigurationPhoto)
  public loadChatbotConfigurationPhoto(
    context: StateContext<ChatbotConfigurationsStateModel>,
    action: LoadChatbotConfigurationPhoto
  ) {
    return this.chatbotConfigurationsUseCases.loadChatbotConfigurationPhoto(context, action);
  }
}
