<ng-template [ngIf]="currentUser">
  <apx-navigation-bar
    *ngIf="navigationBarConfig"
    [user]="currentUser"
    [config]="navigationBarConfig"
    (logoutClicked)="onLogout()"
  >
  </apx-navigation-bar>
</ng-template>
<div
  id="content"
  [class.collapsed]="collapsed"
  [class.logged-in]="currentUser"
  [class.logged-out]="!currentUser"
  [class.info-bar]="infoBarService.infoBarData$ | async"
>
  <div class="wrap" *ngIf="!(errorMessage$ | async)">
    <apx-loading-spinner>
      <router-outlet></router-outlet>
    </apx-loading-spinner>
  </div>
  <apx-no-access [message]="errorMessage$ | async" *ngIf="errorMessage$ | async"></apx-no-access>
</div>

<apx-info-bar (clicked)="infoBarCallback($event)"></apx-info-bar>

<apx-host #verificationsPanelHost></apx-host>

<ng-template #verificationsPanelView>
  <apx-slide-panel [id]="'verifications'">
    <app-verifications-panel></app-verifications-panel>
  </apx-slide-panel>
</ng-template>
