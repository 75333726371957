import { ChatPreview } from '../../../generated';
import { ChatPreview as ChatPreviewModel, VerificationPreview } from '../../models/chat-preview.model';
import { VerificationStatus } from '../../models/verification-status.model';
import { UpdateVerificationRequestOnChatPreview } from '../stores/chat-preview/chat-preview.action';
import { EditMessageModel } from '../../models/edit-message.model';
import { EditMessage } from '../../models/verifications-chat-topic-info.model';

export class ChatPreviewMapper {
  public static mapToChatPreviewModels(previews: ChatPreview[]): ChatPreviewModel[] {
    if (!previews) {
      return [];
    }

    return previews.map(p => {
      return this.mapToChatPreviewModel(p);
    });
  }

  public static mapToChatPreviewModel(preview: ChatPreview): ChatPreviewModel {
    return {
      id: preview.id,
      title: preview.title,
      startedAt: preview.startedAt,
      active: preview.active,
      hasUpdate: preview.hasUpdate,
      verificationPreviews: !preview.verificationRequests
        ? []
        : preview.verificationRequests.map(s => {
            return {
              chatId: s.chatId,
              chatMessageId: s.chatMessageId,
              verificationId: s.requestId,
              status: VerificationStatus[s.status],
              requestedOn: s.requestedOn,
              updatedAt: s.updatedAt,
              description: s.description,
              assignee: {
                fullName: s.assignee?.userFullName,
                photoId: s.assignee?.userPhotoId,
                organization: s.assignee?.assigneeUserOrganization,
                userId: s.assignee?.userId
              },
              requestedBy: s.requestedBy
            } as VerificationPreview;
          })
    };
  }

  public static mapToVerificationPreview(action: UpdateVerificationRequestOnChatPreview): VerificationPreview {
    return {
      chatId: action.payload.chatId,
      status: action.payload.status,
      verificationId: action.payload.verificationId,
      chatMessageId: action.payload.chatMessageId,
      assignee: action.payload.verification?.assignee,
      updatedAt: action.payload.verification?.updatedAt
    };
  }

  public static mapToChatVerificationPreviewEditMessage(editMessage: EditMessage): EditMessageModel {
    if (!editMessage) {
      return undefined;
    }
    return {
      editedContent: editMessage.editedContent,
      terms: editMessage.terms,
      referencedSpans: editMessage.referencedSpans,
      user: {
        userId: editMessage.user.userId,
        fullName: editMessage.user.fullName,
        photoId: editMessage.user.photoId
      }
    };
  }
}
