import { StateContext } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StoreValueStatus } from '../../../../models/store-value-status.model';
import { PhotoDAO } from '../../../daos/photo.dao';
import { ChatbotConfigurationMapper } from '../../../mappers/chatbot-configuration-mapper';
import { ChatbotConfigurationsService } from '../../../services/chatbotconfigurations/chatbot-configurations.service';
import { LoadChatbotConfigurationPhoto, LoadChatbotConfigurations } from '../chatbot-configurations.action';
import { ChatbotConfigurationsStateModel } from '../chatbot-configurations.state';

export class ChatbotConfigurationsUseCases {
  constructor(private chatbotConfigurationsService: ChatbotConfigurationsService) {}

  public loadChatbotConfigurations(
    context: StateContext<ChatbotConfigurationsStateModel>,
    action: LoadChatbotConfigurations
  ) {
    context.patchState({
      chatbotConfigurationsStatus: StoreValueStatus.LOADING
    });

    return this.chatbotConfigurationsService.loadChatbotConfigurations().pipe(
      tap(response => {
        const mappedValues = response.map(i => ChatbotConfigurationMapper.mapChatbotConfigurationFromResponse(i));

        context.patchState({
          chatbotConfigurations: mappedValues,
          chatbotConfigurationsStatus: StoreValueStatus.LOADED
        });
      }),
      catchError(err => {
        context.patchState({
          chatbotConfigurationsStatus: StoreValueStatus.NOT_LOADED
        });
        return throwError(err);
      })
    );
  }

  public loadChatbotConfigurationPhoto(
    context: StateContext<ChatbotConfigurationsStateModel>,
    action: LoadChatbotConfigurationPhoto
  ) {
    context.patchState({
      chatbotConfigurationsPhotosStatus: StoreValueStatus.LOADING
    });

    const data = cloneDeep(context.getState().chatbotConfigurations);

    const foundIndex = data.findIndex(item => item.photoId === action.payload.photoId);

    if (foundIndex > -1) {
      data[foundIndex].photo = PhotoDAO.getPhotoUrl(action.payload.photoId);
    }

    context.patchState({
      chatbotConfigurations: data,
      chatbotConfigurationsPhotosStatus: StoreValueStatus.LOADED
    });
  }
}
