<div class="verifications-panel-wrapper">
  <div class="header-wrapper">
    <span>{{ 'VERIFICATIONS.PANEL_TITLE' | translate }}</span>
  </div>
  <div class="content-wrapper">
    <apx-accordion-list
      #accordion
      [headerPanelRef]="headerPanel"
      [editHeaderPanelRef]="editHeaderPanelRef"
      [editPanelRef]="editPanelRef"
      [entity]="'Verifications'"
      [data$]="verificationsData$"
      [rowHeight]="60"
      [cssClass]="'verifications'"
      [allowReRender]="true"
      [scrollViewportMaxHeight]="'calc(100vh - 130px)'"
      [viewportMargin]="openedChatMessageId ? -130 : 141"
      (openClose)="onOpenCloseVerification($event)"
    >
      <ng-template #headerPanel let-verification>
        <ng-template
          [ngTemplateOutlet]="rowHeaderTemplate"
          [ngTemplateOutletContext]="{ $implicit: verification, isOpen: false }"
        >
        </ng-template>
      </ng-template>
      <ng-template #editHeaderPanelRef let-verification>
        <ng-template
          [ngTemplateOutlet]="rowHeaderTemplate"
          [ngTemplateOutletContext]="{ $implicit: verification, isOpen: true }"
        >
        </ng-template>
      </ng-template>
      <ng-template #editPanelRef let-verification>
        <ng-template [ngTemplateOutlet]="rowContentTemplate" [ngTemplateOutletContext]="{ $implicit: verification }">
        </ng-template>
      </ng-template>
    </apx-accordion-list>
  </div>
</div>

<ng-template #rowHeaderTemplate let-verification let-isOpen="isOpen">
  <div class="verification-header-wrapper">
    <div class="verification-header-icon">
      <ng-template [ngIf]="verification.status === VerificationStatus.REQUESTED">
        <mat-icon class="requested">schedule</mat-icon>
      </ng-template>
      <ng-template [ngIf]="verification.status === VerificationStatus.IN_PROGRESS">
        <mat-icon class="in-progress">schedule</mat-icon>
      </ng-template>
      <ng-template [ngIf]="verification.status === VerificationStatus.COMPLETED">
        <mat-icon class="completed">check_circle</mat-icon>
      </ng-template>
    </div>
    <div class="verification-header-info">
      <div class="verification-date">
        <span>{{ DateUtils.formatUTC(verification.requestedOn, 'dd MMM yyyy | h:mm a z') }}</span>
      </div>
      <div
        class="verification-description"
        [ngClass]="isOpen ? 'line-clamp' : 'truncate'"
        [matTooltip]="verification.description"
        [matTooltipPosition]="'above'"
      >
        <span>{{ verification.description }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rowContentTemplate let-verification>
  <div class="verification-content-wrapper">
    <ul class="timeline">
      <ng-template
        [ngTemplateOutlet]="timelineItemRequested"
        [ngTemplateOutletContext]="{ $implicit: verification, requestId: verification.requestId, editable: false }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="timelineItemNow"
        [ngTemplateOutletContext]="{
          $implicit: verification,
          requestId: verification.requestId,
          editable: verification.chatMessageId === chatMessageId && verification.status !== VerificationStatus.COMPLETED
        }"
      ></ng-template>
    </ul>
  </div>
</ng-template>

<ng-template #timelineItemRequested let-verification>
  <li class="requested">
    <ng-template
      [ngTemplateOutlet]="timestampWrapper"
      [ngTemplateOutletContext]="{ $implicit: verification.requestedOn }"
    >
    </ng-template>
    <div class="status-wrapper requested view-mode">
      <span>Requested</span>
    </div>
    <ng-template
      [ngTemplateOutlet]="userWrapper"
      [ngTemplateOutletContext]="{
        $implicit: verification.requestedBy,
        requestId: verification.requestId,
        editable: false
      }"
    >
    </ng-template>
  </li>
</ng-template>

<ng-template #timelineItemNow let-verification let-requestId="requestId" let-editable="editable">
  <li
    [class.requested]="verification.status === VerificationStatus.REQUESTED"
    [class.in-progress]="verification.status === VerificationStatus.IN_PROGRESS"
    [class.completed]="verification.status === VerificationStatus.COMPLETED"
  >
    <ng-template
      [ngTemplateOutlet]="timestampWrapper"
      [ngTemplateOutletContext]="{ $implicit: verification.updatedAt }"
    >
    </ng-template>
    <div
      class="status-wrapper"
      [ngClass]="editable ? 'edit-mode' : 'view-mode'"
      (click)="setItemType($event, editable, 'status')"
      [class.requested]="verification.status === VerificationStatus.REQUESTED"
      [class.in-progress]="verification.status === VerificationStatus.IN_PROGRESS"
      [class.completed]="verification.status === VerificationStatus.COMPLETED"
    >
      <span>{{ verification.status.replace('_', ' ') | titlecase }}</span>
      <div
        *ngIf="inlineEditingType === 'status' && editable"
        class="status-badge-btn-container"
        (click)="changeVerificationRequestStatus(verification)"
        apxClickOutSide
        (clickOutside)="onClickedOutside($event, 'status')"
      >
        <div
          class="status-badge"
          [ngClass]="verification.status === VerificationStatus.REQUESTED ? 'in-progress' : 'requested'"
        >
          <span>{{ verification.status === 'REQUESTED' ? 'In progress' : 'Requested' }}</span>
        </div>
      </div>
    </div>
    <ng-template [ngIf]="verification.assignee" [ngIfElse]="unassigned">
      <ng-template
        [ngTemplateOutlet]="userWrapper"
        [ngTemplateOutletContext]="{
          $implicit: verification.assignee,
          requestId: verification.requestId,
          editable: verification.chatMessageId === chatMessageId && verification.status !== VerificationStatus.COMPLETED
        }"
      >
      </ng-template>
    </ng-template>
    <ng-template #unassigned>
      <ng-template [ngIf]="inlineEditingType === 'assignee' && editable" [ngIfElse]="viewMode">
        <ng-template
          [ngTemplateOutlet]="assigneeTemplate"
          [ngTemplateOutletContext]="{ $implicit: verification.requestId }"
        >
        </ng-template>
      </ng-template>
      <ng-template #viewMode>
        <div
          class="assignee-wrapper unassigned"
          [ngClass]="editable ? 'edit-mode' : 'view-mode'"
          (click)="setItemType($event, editable, 'assignee')"
        >
          <span>Unassigned</span>
        </div>
      </ng-template>
    </ng-template>
  </li>
</ng-template>

<ng-template #timestampWrapper let-timestamp>
  <div class="timestamp-wrapper">
    <span>{{ DateUtils.formatUTC(timestamp, 'dd MMM yyyy | h:mm a z').split('|')[0] }}</span>
    <span>{{ DateUtils.formatUTC(timestamp, 'dd MMM yyyy | h:mm a z').split('|')[1] }}</span>
  </div>
</ng-template>

<ng-template #userWrapper let-user let-requestId="requestId" let-editable="editable">
  <ng-template [ngIf]="inlineEditingType === 'assignee' && editable" [ngIfElse]="viewMode">
    <ng-template [ngTemplateOutlet]="assigneeTemplate" [ngTemplateOutletContext]="{ $implicit: requestId }">
    </ng-template>
  </ng-template>
  <ng-template #viewMode>
    <div
      class="user-wrapper"
      [ngClass]="editable ? 'edit-mode' : 'view-mode'"
      (click)="setItemType($event, editable, 'assignee')"
    >
      <div class="user-content">
        <ng-template [ngIf]="user.photoId" [ngIfElse]="defaultIcon">
          <img [src]="PhotoDAO.getPhotoUrl(user.photoId)" />
        </ng-template>
        <ng-template #defaultIcon>
          <mat-icon>person</mat-icon>
        </ng-template>
      </div>
      <div class="user-content">
        <span>{{ user.fullName }}</span>
        <span>{{ user.organization?.name }}</span>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #assigneeTemplate let-requestId>
  <apx-template-picker
    [control]="form"
    [removeSelection]="false"
    [isJurisdiction]="false"
    [defaultIcon]="'person'"
    [panelCssClass]="'assignee-panel'"
    [availableOptions$]="assignees$"
    [optionTemplate]="optionTemplateRef"
    [shouldAutoFocus]="true"
    (valueChange)="changeVerificationRequestAssignee($event, requestId)"
    apxClickOutSide
    (clickOutside)="onClickedOutside($event, 'assignee')"
  ></apx-template-picker>

  <ng-template #optionTemplateRef let-option>
    <div class="option-wrapper-picker">
      <ng-template [ngIf]="option.image" [ngIfElse]="defaultIconTemplate">
        <img alt="image" [src]="option.image" />
      </ng-template>
      <ng-template #defaultIconTemplate>
        <ng-template [ngIf]="option.id && option.id !== UNASSIGNED_ID">
          <mat-icon>person</mat-icon>
        </ng-template>
      </ng-template>
      <span>{{ option.label }}</span>
    </div>
  </ng-template>
</ng-template>
