import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@apiax/web-commons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthorizationService } from 'shared/core';
import { Store } from '@ngxs/store';
import { UpdateErrorMessage } from '../stores/errors/chat-errors.action';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UnauthorizedInterceptor implements HttpInterceptor {
  private exceptionName = 'exceptionName';

  constructor(
    private authorizationService: AuthorizationService,
    private alerts: NotificationService,
    private store: Store
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        let result = throwError(err);
        if (err.status === 401 && !request.url.includes('logout')) {
          // auto logout if 401 response returned from api
          this.authorizationService.logout();
        } else if (err.status === 403) {
          if (err?.error?.data?.[this.exceptionName]) {
            this.store.dispatch(new UpdateErrorMessage({ exceptionName: err.error.data[this.exceptionName] }));
            return throwError(err);
          }
          this.alerts.showAlert({
            type: 'error',
            message: 'Access denied'
          });
          result = EMPTY;
        }
        return result;
      })
    );
  }
}
