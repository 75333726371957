import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'shared/core';

@Injectable()
export class ActivityAllowedGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot) {
    if (Object.prototype.hasOwnProperty.call(route.data, 'activities')) {
      return this.authorizationService.isAllowed(route.data.activities);
    }
    return true;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute);
  }
}
