import { StateContext } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { VerificationStatus } from 'app/models/verification-status.model';
import { VerificationRequestsApiService, VerifyAnswerRequest } from '../../../../../generated';
import { StoreValueStatus } from '../../../../models/store-value-status.model';
import { VerificationMode } from '../../../../models/verification-mode';
import { AssignableUsersMapper } from '../../../mappers/assignable-users-mapper';
import { ChatSessionVerificationsMapper } from '../../../mappers/chat-session-verifications-mapper';
import {
  CreateVerificationRequest,
  GetChatSessionWithVerificationRequests,
  LoadAssignableUsers,
  SelectedChatMessage,
  UpdateChatSessionWithVerificationRequestsActivity,
  UpdateChatVerification,
  UpdateVerificationRequestAssignee,
  UpdateVerificationRequestStatus,
  VerifyAnswer
} from '../verifications.action';
import { VerificationsStateModel } from '../verifications.state';
import { ChatPreviewMapper } from '../../../mappers/chat-preview-mapper';

export class VerificationsUseCases {
  constructor(private verificationRequestsApiService: VerificationRequestsApiService) {}

  public createVerificationRequest(context: StateContext<VerificationsStateModel>, action: CreateVerificationRequest) {
    context.patchState({
      lastCreatedVerificationRequest: undefined
    });

    return this.verificationRequestsApiService
      .create({
        chatId: action.payload.chatId,
        chatMessageId: action.payload.chatMessageId,
        description: action.payload.description
      })
      .pipe(
        tap(response => {
          context.patchState({
            lastCreatedVerificationRequest: {
              verificationId: response.requestId,
              chatId: action.payload.chatId,
              chatMessageId: action.payload.chatMessageId,
              status: VerificationStatus.REQUESTED
            }
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getChatSessionWithVerificationRequests(
    context: StateContext<VerificationsStateModel>,
    action: GetChatSessionWithVerificationRequests
  ) {
    context.patchState({
      chatSessionRequestsFieldLoadStatus: StoreValueStatus.LOADING
    });
    return this.verificationRequestsApiService.getChat(action.payload.chatId).pipe(
      tap(response => {
        context.patchState({
          chatSessionWithVerificationRequests:
            ChatSessionVerificationsMapper.mapToChatSessionWithVerifications(response),
          chatSessionRequestsFieldLoadStatus: StoreValueStatus.LOADED
        });
      }),
      catchError(err => {
        context.patchState({
          chatSessionRequestsFieldLoadStatus: StoreValueStatus.NOT_LOADED
        });
        return throwError(err);
      })
    );
  }

  public verifyVerificationRequest(context: StateContext<VerificationsStateModel>, action: VerifyAnswer) {
    const clonedVerificationRequests = cloneDeep(
      context.getState().chatSessionWithVerificationRequests.verificationRequests
    );
    const clonedChatHistory = cloneDeep(context.getState().chatSessionWithVerificationRequests.chatHistory);
    const currentVerificationRequest = clonedVerificationRequests.find(
      vr => vr.chatMessageId === action.payload.chatMessageId
    );
    const request: VerifyAnswerRequest = {
      chatId: action.payload.chatId,
      chatMessageId: action.payload.chatMessageId,
      requestId: currentVerificationRequest.requestId,
      answer: action.payload.answer
    };
    clonedVerificationRequests.map(vr => {
      if (vr.chatMessageId === action.payload.chatMessageId) {
        vr.status = VerificationStatus.COMPLETED;
      }
    });
    if (action.payload.mode === VerificationMode.EDIT) {
      clonedChatHistory.map(clonedChat => {
        if (clonedChat.messageId === action.payload.chatMessageId) {
          clonedChat.editMessage = {
            editedContent: action.payload.answer,
            user: currentVerificationRequest.assignee
              ? {
                  fullName: currentVerificationRequest.assignee.fullName,
                  userId: currentVerificationRequest.assignee.userId,
                  photoId: currentVerificationRequest.assignee.photoId
                }
              : null
          };
        }
      });
    }
    return this.verificationRequestsApiService.verify(request).pipe(
      tap(() => {
        context.patchState({
          chatSessionWithVerificationRequests: {
            ...context.getState().chatSessionWithVerificationRequests,
            chatHistory: [...clonedChatHistory],
            verificationRequests: [...clonedVerificationRequests]
          }
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public updateSelectedChatMessage(context: StateContext<VerificationsStateModel>, action: SelectedChatMessage) {
    context.patchState({
      selectedChatMessage: action.payload.chatMessageId
    });
  }

  public loadAssignableUsers(context: StateContext<VerificationsStateModel>, _action: LoadAssignableUsers) {
    context.patchState({
      assignableUsersLoadStatus: StoreValueStatus.LOADING
    });
    return this.verificationRequestsApiService.getAssignableUsers().pipe(
      tap(response => {
        context.patchState({
          assignableUsers: AssignableUsersMapper.mapToUserInfos(response),
          assignableUsersLoadStatus: StoreValueStatus.LOADED
        });
      }),
      catchError(err => {
        context.patchState({
          assignableUsersLoadStatus: StoreValueStatus.NOT_LOADED
        });
        return throwError(err);
      })
    );
  }

  public updateVerificationRequestStatus(
    context: StateContext<VerificationsStateModel>,
    action: UpdateVerificationRequestStatus
  ) {
    return this.verificationRequestsApiService
      .changeStatus({
        requestId: action.payload.requestId,
        newStatus: action.payload.newStatus
      })
      .pipe(
        tap(response => {
          const updatedVerificationRequests = cloneDeep(context.getState().chatSessionWithVerificationRequests);

          updatedVerificationRequests.verificationRequests.map(obj => {
            if (obj.requestId === action.payload.requestId) {
              obj.status = VerificationStatus[response.newStatus];
              obj.updatedAt = response.updatedAt;
            }
          });

          context.patchState({
            chatSessionWithVerificationRequests: updatedVerificationRequests
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public updateVerificationRequestAssignee(
    context: StateContext<VerificationsStateModel>,
    action: UpdateVerificationRequestAssignee
  ) {
    return this.verificationRequestsApiService
      .changeAssignee({
        requestId: action.payload.requestId,
        newAssignee: action.payload.assigneeId
      })
      .pipe(
        tap(response => {
          let updatedVerificationRequests = cloneDeep(context.getState().chatSessionWithVerificationRequests);

          updatedVerificationRequests.verificationRequests.map(obj => {
            if (obj.requestId === action.payload.requestId) {
              obj.assignee = ChatSessionVerificationsMapper.mapChangeAssigneeUserToUserInfo(response.newAssignee);
              obj.updatedAt = response.updatedAt;
            }
          });

          context.patchState({
            chatSessionWithVerificationRequests: updatedVerificationRequests
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public updateChatVerification(context: StateContext<VerificationsStateModel>, action: UpdateChatVerification) {
    let updatedVerificationRequests = cloneDeep(context.getState().chatSessionWithVerificationRequests);

    const storeVerificationRequest = updatedVerificationRequests.verificationRequests.find(
      verificationRequest =>
        verificationRequest.chatMessageId === action.payload.verificationsChatTopicInfo.chatMessageId
    );
    const updatedVerificationRequest =
      ChatSessionVerificationsMapper.mapVerificationsChatTopicInfoToVerificationRequest(
        action.payload.verificationsChatTopicInfo
      );

    if (storeVerificationRequest) {
      ChatSessionVerificationsMapper.copyVerificationRequestValues(
        storeVerificationRequest,
        updatedVerificationRequest
      );
    } else {
      updatedVerificationRequests.verificationRequests.unshift(updatedVerificationRequest);
    }

    const updatedEditMessage = ChatPreviewMapper.mapToChatVerificationPreviewEditMessage(
      action.payload.verificationsChatTopicInfo.editMessage
    );

    if (updatedEditMessage) {
      let storeChatbotMessage = updatedVerificationRequests.chatHistory.find(
        message => message.messageId === updatedVerificationRequest.chatMessageId
      );
      if (storeChatbotMessage) {
        storeChatbotMessage.editMessage = updatedEditMessage;
      }
    }

    return context.patchState({
      chatSessionWithVerificationRequests: updatedVerificationRequests
    });
  }

  public updateChatSessionWithVerificationRequestsActivity(
    context: StateContext<VerificationsStateModel>,
    action: UpdateChatSessionWithVerificationRequestsActivity
  ) {
    const chatSession = cloneDeep(context.getState().chatSessionWithVerificationRequests);

    if (chatSession.chatId === action.payload.chatId) {
      chatSession.active = action.payload.active;
    }

    context.patchState({
      chatSessionWithVerificationRequests: chatSession
    });
  }
}
