import { ChatbotConfiguration } from '../../models/chatbot-configuration.model';

export class ChatbotConfigurationMapper {
  public static mapChatbotConfigurationFromResponse(response: any): ChatbotConfiguration {
    return {
      id: response.id,
      photoId: response.photoId,
      photo: null,
      name: response.name,
      languages:
        response?.languages?.map(l => ({
          code: l.iso6391,
          name: l.languageName
        })) || [],
      toneOfVoice: response.toneOfVoice
        ? {
            id: response.toneOfVoice.id,
            name: response.toneOfVoice.name
          }
        : undefined,
      groups:
        response.groups?.map(g => ({
          id: g.id,
          name: g.name
        })) || [],
      purpose: response.purpose,
      assistant: response.assistant
        ? {
            id: response.assistant.id,
            name: response.assistant.name
          }
        : undefined,
      apikey: response.apikey,
      documents:
        response.documents?.map(d => ({
          id: d.id,
          name: d.name
        })) || [],
      llmProvider: response.llmProvider
        ? {
            id: response.llmProvider.id,
            name: response.llmProvider.name
          }
        : undefined
    };
  }
}
