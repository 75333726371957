import { ChangeStatusRequest } from '../../../../generated';
import { VerificationMode } from '../../../models/verification-mode';
import { VerificationsChatTopicInfo } from '../../../models/verifications-chat-topic-info.model';

export enum VerificationsAction {
  CreateVerificationRequest = '[Create Verification Request] Creates a new verification request for a chat message',
  GetChatSessionWithVerificationRequests = '[Get the chat session with verification requests] Gets the chat session with verification requests for a specific chat id',
  VerifyAnswer = '[Verify Answer] Verifies an answer',
  SelectedChatMessage = '[Selected Chat Message] The selected chat message, e.g. to scroll to',
  LoadAssignableUsers = '[Load Assignable Users] Loads all the possible users for the assignee field',
  UpdateVerificationRequestStatus = '[Update Verification Request Status] Performs an update status of a verification request',
  UpdateVerificationRequestAssignee = '[Update Verification Request Assignee] Performs an update assignee of a verification request',
  UpdateChatVerification = '[Update Chat Verification] Updates the chat verification',
  UpdateChatSessionWithVerificationRequestsActivity = '[Update Chat Session With Verification Requests Activity] Updates a chat activity'
}

export class CreateVerificationRequest {
  public static readonly type = VerificationsAction.CreateVerificationRequest;

  constructor(public payload: { chatId: string; chatMessageId: string; description: string }) {}
}

export class GetChatSessionWithVerificationRequests {
  public static readonly type = VerificationsAction.GetChatSessionWithVerificationRequests;

  constructor(public payload: { chatId: string }) {}
}

export class VerifyAnswer {
  public static readonly type = VerificationsAction.VerifyAnswer;

  constructor(public payload: { chatId: string; chatMessageId: string; answer: string; mode: VerificationMode }) {}
}

export class SelectedChatMessage {
  public static readonly type = VerificationsAction.SelectedChatMessage;

  constructor(public payload: { chatMessageId: string }) {}
}

export class LoadAssignableUsers {
  public static readonly type = VerificationsAction.LoadAssignableUsers;
}

export class UpdateVerificationRequestStatus {
  public static readonly type = VerificationsAction.UpdateVerificationRequestStatus;

  constructor(public payload: { requestId: string; newStatus?: ChangeStatusRequest.NewStatusEnum }) {}
}

export class UpdateVerificationRequestAssignee {
  public static readonly type = VerificationsAction.UpdateVerificationRequestAssignee;

  constructor(
    public payload: {
      requestId: string;
      assigneeId?: string;
      assigneeName?: string;
      assigneePhotoId?: string;
    }
  ) {}
}

export class UpdateChatVerification {
  public static readonly type = VerificationsAction.UpdateChatVerification;

  constructor(
    public payload: {
      verificationsChatTopicInfo: VerificationsChatTopicInfo;
    }
  ) {}
}

export class UpdateChatSessionWithVerificationRequestsActivity {
  public static readonly type = VerificationsAction.UpdateChatSessionWithVerificationRequestsActivity;

  constructor(
    public payload: {
      chatId: string;
      active: boolean;
    }
  ) {}
}
