import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ConversationLogService } from 'shared/app/services/conversation-log.service';
import { ChatPreviewApiService } from '../../../../generated';
import { ChatPreview } from '../../../models/chat-preview.model';
import { StoreValueStatus } from '../../../models/store-value-status.model';
import {
  AddNewVerificationRequestToChatPreview,
  ChangeConversation,
  ClearChatPreviews,
  DeleteConversation,
  EditConversation,
  FetchChatPreviews,
  ResetChatPreviewHasUpdate,
  UpdateChatPreviewActivity,
  UpdateChatPreviewTitle,
  UpdateVerificationRequestOnChatPreview
} from './chat-preview.action';
import { ChatPreviewUseCases } from './usecases/chat-preview-use-cases';

export interface ChatPreviewStateModel {
  chatPreviews: ChatPreview[];
  chatPreviewsFieldLoadStatus: StoreValueStatus;
}

export const DEFAULT_STATE: ChatPreviewStateModel = {
  chatPreviews: [],
  chatPreviewsFieldLoadStatus: StoreValueStatus.NOT_LOADED
};

@State<ChatPreviewStateModel>({
  name: 'chatPreview',
  defaults: DEFAULT_STATE
})
@Injectable()
export class ChatPreviewState {
  private chatPreviewUseCases: ChatPreviewUseCases;

  constructor(
    store: Store,
    conversationLogService: ConversationLogService,
    chatPreviewApiService: ChatPreviewApiService
  ) {
    this.chatPreviewUseCases = new ChatPreviewUseCases(store, conversationLogService, chatPreviewApiService);
  }

  @Selector()
  static chatPreviews(state: ChatPreviewStateModel): ChatPreview[] {
    return state.chatPreviews;
  }

  @Action(FetchChatPreviews)
  public fetchChatPreviews(context: StateContext<ChatPreviewStateModel>): void {
    this.chatPreviewUseCases.fetchChatPreviews(context);
  }

  @Action(ClearChatPreviews)
  public clearChatPreviews(context: StateContext<ChatPreviewStateModel>): void {
    this.chatPreviewUseCases.clearChatPreviews(context);
  }

  @Action(DeleteConversation)
  public deleteConversation(context: StateContext<ChatPreviewStateModel>, action: DeleteConversation) {
    return this.chatPreviewUseCases.deleteConversation(context, action);
  }

  @Action(EditConversation)
  public editConversation(context: StateContext<ChatPreviewStateModel>, action: EditConversation) {
    return this.chatPreviewUseCases.editConversation(context, action);
  }

  @Action(ChangeConversation)
  public async changeConversation(
    context: StateContext<ChatPreviewStateModel>,
    action: ChangeConversation
  ): Promise<void> {
    return this.chatPreviewUseCases.changeConversation(context, action);
  }

  @Action(AddNewVerificationRequestToChatPreview)
  public addNewVerificationRequestToChatPreview(
    context: StateContext<ChatPreviewStateModel>,
    action: AddNewVerificationRequestToChatPreview
  ) {
    return this.chatPreviewUseCases.addNewVerificationRequestToChatPreview(context, action);
  }

  @Action(ResetChatPreviewHasUpdate)
  public resetChatPreviewHasUpdate(context: StateContext<ChatPreviewStateModel>, action: ResetChatPreviewHasUpdate) {
    return this.chatPreviewUseCases.resetChatPreviewHasUpdate(context, action);
  }

  @Action(UpdateVerificationRequestOnChatPreview)
  public updateVerificationRequestOnChatPreview(
    context: StateContext<ChatPreviewStateModel>,
    action: UpdateVerificationRequestOnChatPreview
  ) {
    return this.chatPreviewUseCases.updateVerificationRequestOnChatPreview(context, action);
  }

  @Action(UpdateChatPreviewActivity)
  public updateChatPreviewActivity(context: StateContext<ChatPreviewStateModel>, action: UpdateChatPreviewActivity) {
    return this.chatPreviewUseCases.updateChatPreviewActivity(context, action);
  }

  @Action(UpdateChatPreviewTitle)
  public updateChatPreviewTitle(context: StateContext<ChatPreviewStateModel>, action: UpdateChatPreviewTitle) {
    return this.chatPreviewUseCases.updateChatPreviewTitle(context, action);
  }
}
