import { StateContext } from '@ngxs/store';
import { UpdateErrorMessage } from '../chat-errors.action';
import { ErrorStateModel } from '../chat-errors.state';

export class ErrorsUseCases {
  private errorMessages = {
    NOT_ALLOWED_ACCORDING_TO_MONITORING_PERMISSION:
      'Your access to this result has expired. <br/> ' +
      'To access an extended date range, please get in touch with your account executive',
    DEFAULT: 'There Unknown error'
  };

  public updateErrorMessage(context: StateContext<ErrorStateModel>, action: UpdateErrorMessage) {
    const exceptionName = action?.payload?.exceptionName;
    if (this.errorMessages[exceptionName]) {
      context.patchState({
        errorMessage: this.errorMessages[exceptionName]
      });
    } else {
      context.patchState({
        errorMessage: this.errorMessages['DEFAULT']
      });
    }
  }
}
