import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { SettingsState } from 'app/domain/stores/settings/settings.state';

import { Collection } from 'app/models/collection.model';

@Component({
  selector: 'app-collection-selector',
  templateUrl: './collection-selector.component.html',
  styleUrls: ['./collection-selector.component.scss']
})
export class CollectionSelectorComponent implements OnInit {
  @Input() selectedCollectionId: string;
  @Input() isDisabled: boolean;
  @Output() collectionSelected = new EventEmitter<Collection>();

  highlightedCollection: Collection | null = null;
  collections: Collection[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {}

  onCollectionClicked(collection: Collection): void {
    if (collection.id === this.highlightedCollection?.id) {
      this.collectionSelected.emit(null);
      this.highlightedCollection = null;
    } else {
      this.collectionSelected.emit(collection);
      this.highlightedCollection = collection;
    }
  }

  ngOnInit(): void {
    this.store.select(SettingsState.getAvailableCollections).subscribe(collections => {
      this.collections = collections;
      this.cdr.markForCheck();
    });
    this.store.select(SettingsState.getCollectionId).subscribe(collectionId => {
      this.highlightedCollection = this.collections.find(collection => collection.id === collectionId) || null;
      this.cdr.markForCheck();
    });
  }
}
