import {
  ChatbotConfigurationBal,
  Activity,
  ActivityResult,
  ActivityResultDetail,
  ChatMessageBal,
  ChatSessionBalResponse,
  ChatUser,
  ChatVerificationRequest,
  Collection,
  EditMessage,
  RegPropWithOptions,
  Result,
  Ruleset,
  Span,
  Term
} from '../../../generated';

import { ChatbotMessage as ChatbotMessageModel } from '../../models/chatbot-message';
import {
  CrossBorderActionProperty,
  CrossBorderActionPropertyDetail,
  CrossBorderActionPropertyResult,
  MessageReferencedSpans,
  RuleSet,
  Term as TermModel
} from '../../models/chatbot-response';
import { EditMessageModel } from '../../models/edit-message.model';
import { UserInfo } from '../../models/user-info.model';
import { VerificationStatus } from '../../models/verification-status.model';
import { VerificationRequestBal } from '../../models/verification-request-bal.model';
import { ChatSessionBal } from 'app/models/chat-session-bal.model';
import { VerificationsChatTopicInfo } from 'app/models/verifications-chat-topic-info.model';
import { RegpropModel } from 'app/models/regprop.model';
import { ChatbotConfigurationSession } from 'app/models/chatbot-configuration-session.model';
import { Collection as CollectionModel } from '../../models/collection.model';
import { ChatbotMessageResult } from 'app/models/chatbot-message-result.model';

export class ChatSessionBalMapper {
  public static mapToChatSessionBal(chatSessionBalResponse: ChatSessionBalResponse): ChatSessionBal {
    return {
      chatId: chatSessionBalResponse.chatId,
      title: chatSessionBalResponse.title,
      startedAt: new Date(chatSessionBalResponse.startedAt),
      closedAt: new Date(chatSessionBalResponse.closedAt),
      chatHistory: this.mapToChatMessage(chatSessionBalResponse.chatHistory),
      verificationRequests: this.mapToVerificationRequests(chatSessionBalResponse.chatVerificationRequests),
      user: this.mapChatUserToUserInfo(chatSessionBalResponse.chatUser),
      collectionId: chatSessionBalResponse.collectionId,
      chatbotConfiguration: this.mapToChatbotConfiguration(chatSessionBalResponse.chatbotConfiguration)
    };
  }

  public static mapVerificationsChatTopicInfoToVerificationRequest(
    verificationRequestTopic: VerificationsChatTopicInfo
  ): VerificationRequestBal {
    return {
      requestId: verificationRequestTopic.verification.requestId,
      chatMessageId: verificationRequestTopic.chatMessageId,
      status: VerificationStatus[verificationRequestTopic.verification.status],
      updatedAt: new Date(verificationRequestTopic.verification.updatedAt),
      assignee: verificationRequestTopic.verification.assignee
        ? { fullName: verificationRequestTopic.verification.assignee.fullName }
        : null
    };
  }

  public static copyVerificationRequestValues(target: VerificationRequestBal, source: VerificationRequestBal) {
    target.status = source.status;
    target.updatedAt = source.updatedAt;
    target.assignee = source.assignee;
  }

  private static mapToChatMessage(chatHistory: ChatMessageBal[]): ChatbotMessageModel[] {
    if (!chatHistory) {
      return [];
    }
    return chatHistory.map(message => {
      return {
        messageId: message.chatMessageId,
        message: message.content,
        terms: this.mapToTerms(message.terms),
        referencedSpans: this.mapToReferenceSpans(message.referencedSpans),
        source: message.role == ChatMessageBal.RoleEnum.USER ? 'user' : 'bot',
        type: 'text',
        editMessage: this.mapEditMessageToEditMessageModel(message.edit),
        regprop: this.mapRegpropToRegpropModel(message.regprop),
        result: this.mapResultToResultModel(message.result),
        evaluationInformation: message.evaluationInformation,
        evaluatedRulesets:
          message.evaluatedRulesets == null
            ? undefined
            : message.evaluatedRulesets.map(ruleset => this.mapToRuleSetModel(ruleset))
      };
    });
  }

  private static mapToTerms(terms: Term[]): TermModel[] {
    if (!terms) {
      return [];
    }
    return terms?.map(t => {
      return {
        term: t.term,
        termId: t.termId,
        id: t.id,
        definition: t.definition
      };
    });
  }

  private static mapToReferenceSpans(referencedSpans: Span[]): MessageReferencedSpans[] {
    if (!referencedSpans) {
      return [];
    }
    return referencedSpans.map(s => {
      return {
        id: s.id,
        filename: s.filename,
        section: s.section,
        text: s.text
      };
    });
  }

  private static mapToVerificationRequests(requests: ChatVerificationRequest[]): VerificationRequestBal[] {
    if (!requests) {
      return [];
    }
    return requests.map(r => {
      return {
        requestId: r.requestId,
        chatMessageId: r.chatMessageId,
        status: VerificationStatus[r.status],
        updatedAt: new Date(r.updatedAt),
        assignee: { fullName: r.assignee?.userFullName }
      };
    });
  }

  private static mapChatUserToUserInfo(user: ChatUser): UserInfo {
    if (!user) {
      return undefined;
    }
    return {
      userId: user.userId,
      fullName: user.userFullName,
      photoId: user.userPhotoId
    };
  }

  private static mapEditMessageToEditMessageModel(editMessage: EditMessage): EditMessageModel {
    if (!editMessage) {
      return undefined;
    }
    return {
      editedContent: editMessage.editedContent,
      terms: editMessage.terms,
      referencedSpans: editMessage.referencedSpans,
      user: {
        userId: editMessage.user.userId,
        fullName: editMessage.user.userFullName,
        photoId: editMessage.user.userPhotoId
      }
    };
  }

  private static mapRegpropToRegpropModel(regprop: RegPropWithOptions): RegpropModel {
    if (!regprop) {
      return undefined;
    }
    return {
      termId: regprop.termId,
      values: regprop.values.map(v => {
        return {
          termId: v.termId,
          operator: v.operator
        };
      }),
      choice: regprop.choice
    };
  }
  public static mapToChatbotConfiguration(chatbotConfiguration: ChatbotConfigurationBal): ChatbotConfigurationSession {
    return {
      collections: this.mapToCollection(chatbotConfiguration.collections),
      photoId: chatbotConfiguration.photoId
    };
  }

  public static mapToCollection(collections: Collection[]): CollectionModel[] {
    if (!collections) {
      return undefined;
    }
    return collections.map(c => {
      return {
        id: c.id,
        name: c.name
      };
    });
  }

  private static mapResultToResultModel(result: Result): ChatbotMessageResult {
    if (!result) {
      return undefined;
    }
    return {
      activities: result.activities.map(activity => this.mapToCrossBorderActionPropertyModel(activity)),
      additionalActivities: result.additionalActivities.map(activity =>
        this.mapToCrossBorderActionPropertyModel(activity)
      )
    };
  }

  private static mapToCrossBorderActionPropertyModel(activity: Activity): CrossBorderActionProperty {
    return {
      titleLabelId: activity.titleLabelId,
      productTermId: activity.productTermId,
      productNameLabel: activity.productNameLabel,
      jurisdiction: activity.jurisdiction,
      proActive: this.mapToCrossBorderActionPropertyResultModel(activity.proActive),
      reverse: this.mapToCrossBorderActionPropertyResultModel(activity.reverse)
    };
  }

  private static mapToCrossBorderActionPropertyResultModel(
    activityResult: ActivityResult
  ): CrossBorderActionPropertyResult {
    return {
      value: activityResult.value,
      restrictions: activityResult.restrictions.map(restriction =>
        this.mapToCrossBorderActionPropertyDetailModel(restriction)
      ),
      remarks: activityResult.remarks.map(remark => this.mapToCrossBorderActionPropertyDetailModel(remark)),
      additionalInformation: activityResult.additionalInformation.map(additionalInfo =>
        this.mapToCrossBorderActionPropertyDetailModel(additionalInfo)
      )
    };
  }

  private static mapToCrossBorderActionPropertyDetailModel(
    activityResultDetail: ActivityResultDetail
  ): CrossBorderActionPropertyDetail {
    return {
      labelId: activityResultDetail.labelId,
      isHardRestriction: activityResultDetail.isHardRestriction,
      jurisdiction: activityResultDetail.jurisdiction
    };
  }

  private static mapToRuleSetModel(ruleset: Ruleset): RuleSet {
    return {
      url: ruleset.url,
      product: ruleset.product
    };
  }
}
