import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { getConfig } from 'shared/app';

@Injectable({
  providedIn: 'root'
})
export class ChatbotConfigurationsService {
  constructor(private httpClient: HttpClient) {}

  private apiUrl(): string {
    return `${getConfig('apiUrl')}`;
  }

  loadChatbotConfigurations(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.apiUrl()}/chat/configurations`, {});
  }
}
