import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ChatSessionBal } from 'app/models/chat-session-bal.model';
import { StoreValueStatus } from 'app/models/store-value-status.model';
import { ChatBalApiService } from 'generated';
import { GetChatSessionBal, UpdateChatVerificationBal } from './chat-bal.action';
import { BalUseCases } from './usecases/chat-bal-use-cases';

export interface BalStateModel {
  chatSession: ChatSessionBal;
  chatSessionLoadStatus: StoreValueStatus;
  errorMessage: string;
}

export const DEFAULT_STATE: BalStateModel = {
  chatSession: undefined,
  chatSessionLoadStatus: StoreValueStatus.NOT_LOADED,
  errorMessage: undefined
};

@State<BalStateModel>({
  name: 'bal',
  defaults: DEFAULT_STATE
})
@Injectable()
export class BalState {
  private balUseCases: BalUseCases;

  constructor(chatBalApiService: ChatBalApiService) {
    this.balUseCases = new BalUseCases(chatBalApiService);
  }

  @Selector()
  public static chatSessionBal(state: BalStateModel): ChatSessionBal {
    return state.chatSession;
  }

  @Selector()
  public static chatSessionError(state: BalStateModel): string {
    return state.errorMessage;
  }

  @Selector()
  public static chatSessionLoadStatus(state: BalStateModel): StoreValueStatus {
    return state.chatSessionLoadStatus;
  }

  @Action(GetChatSessionBal)
  public getChatSessionBal(context: StateContext<BalStateModel>, action: GetChatSessionBal) {
    return this.balUseCases.getChatSession(context, action);
  }

  @Action(UpdateChatVerificationBal)
  public UpdateChatVerificationBal(context: StateContext<BalStateModel>, action: UpdateChatVerificationBal) {
    return this.balUseCases.updateChatVerificationBal(context, action);
  }
}
